import api from '@/libs/axios'


export default {
  async create(payload) {
    const response = await api.post('/admin/goga-exam-levels', payload)
    return response.data.data.new_document || null
  },
  async update(payload) {
    let response = await api.put('/admin/goga-exam-levels', payload)
    return response
  },
  async delete(payload) {
    const response = await api.delete('/admin/goga-exam-levels', {
      data: payload,
    })
    return response
  },
  async getList(params) {
    let response = await api.get('/admin/goga-exam-levels', {
      params,
    })
    return response.data.data
  },
  async get(params) {
    let response = await api.get('/admin/goga-exam-levels/detail', {
      params,
    })
    return response.data.data.document || null
  },
}
