<template>
    <div class="page-content">
        <page-breadcrumb title="Exams" class="mb-2" />
        
		<div class="d-flex justify-content-start align-items-center">
			<form-generator :schema="filter_schema" :model="filter" style="width: 100%" />
			<data-create-popup title="Create Group"
				:schema="create_schema" :default-data="defaultObject"
				:create-data-fn="createItem" @create="$refs.group_list.getList()"
			/>
		</div>
        <data-table-ssr id="group_list" ref="group_list"
            :columns="fields" :get-list-fn="getList"
            :to-edit-fn="toEditPage"
        />
    </div>
</template>
<script>
const create_schema = [
  {
    cols: 12,
    fields: [
      { label: 'Type', field: 'type', input_type: 'select', 
	  	options:[
        {text: "Entry test", value: 1},
        {text: "Từ vựng", value: 2},
		{text: "Nghe", value: 3},
		{text: "Tương tác nghe nói", value: 4}
      	],
		validate: { required: true }
	  },
      { label: 'Level', field: 'title', input_type: 'ui-component', validate: { required: true } },
      { label: 'Level Text', field: 'level_text', input_type: 'ui-component', validate: { required: true }},
      { label: 'Min Score', field: 'min_score', input_type: 'nummer', validate: { required: true } },
      { label: 'Max Score', field: 'max_score', input_type: 'nummer', validate: { required: true } },
      { label: 'What you can do Title', field: 'what_can_do_title', input_type: 'ui-component', validate: { required: true }},
      { label: 'What you can do Content', field: 'what_can_do_content', input_type: 'ui-component', validate: { required: true }},
      { label: 'Next Step Title', field: 'next_step_title', input_type: 'ui-component', validate: { required: true }},
      { label: 'Next Step Content', field: 'next_step_content', input_type: 'ui-component', validate: { required: true }},
    ]
  }
];

const fields = [
	{ label: 'Type', field: 'type', input_type: 'select', options:[
        {text: "Entry test", value: 1},
        {text: "Từ vựng", value: 2},
		{text: "Nghe", value: 3},
		{text: "Tương tác nghe nói", value: 4}
	]},
    { label: 'Level', field: 'title', display_type: 'translatable-text' },
    { label: 'Level Text', field: 'level_text', display_type: 'translatable-text' },
    { label: 'Min Score', field: 'min_score', display_type: 'nummer' },
    { label: 'Max Score', field: 'max_score', display_type: 'nummer' },
    { label: 'What You Can Do', field: 'what_can_do_content', display_type: 'translatable-richtext'},
    { label: 'Next Step', field: 'next_step_content', display_type: 'translatable-richtext'},
];
const filter_schema = [
  { cols: 3, fields: [{ label: 'Type', field: 'type', input_type: 'select', options:[
        {text: "Entry test", value: 1},
        {text: "Từ vựng", value: 2},
		{text: "Nghe", value: 3},
		{text: "Tương tác nghe nói", value: 4}
	]}] },
];
import defaultObject from '../defaultObject.js'
import service from '../service'
export default {
    data(){
    return{
      create_schema,
      fields,
      total: 0,
      defaultObject: JSON.parse(JSON.stringify(defaultObject)),
	  filter_schema,
	  filter: {type: 1}
    }
  },
  watch: {
    filter: {
      handler() {
        this.$refs.group_list.getList();
      },
      deep: true,
    },
  },
  methods:{
    async createItem(data) {
      let new_item = await service.create({ ...data });
      return new_item;
    },
    async getList({ limit, page }) {
      let response_data = await service.getList({
        query: JSON.stringify(this.filter),
        page, limit
      });
      let list = [], total = 0;
      if (response_data) {
        list = response_data.list;
        total = response_data.total;
        this.total = total;
      }
      return { list, total };
    },
    toEditPage({ _id }) {
      this.$router.push({ name: 'goga-exam-level-detail', params: { id: _id } });
    },
  }
}
</script>